import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    Visitor: any;
  }
}

@Injectable()
export class AdobeService {
  private _visitor: any;
  private _domain: string;

  constructor() {
    //Gathering the Cookie Domain for .com.au websites
    if (window.location.hostname.indexOf('com.au') > -1) {
      // if not com.au domain then
      const domainSplit = window.location.hostname.split('.');
      if (domainSplit.length === 3) {
        this._domain = window.location.hostname;
      } else if (domainSplit.length > 3) {
        const sp = domainSplit.length - 3;
        const domainArray = [domainSplit[sp], domainSplit[sp + 1], domainSplit[sp + 2]];
        this._domain = domainArray.join('.');
      }
    }
  }

  private setVisitorInstance() {
    if (window.Visitor === undefined) {
      console.log('Adobe VisitorId Service is not loaded !');
      return;
    }

    this._visitor = window.Visitor.getInstance(environment.adobe.orgID, {
      cookieDomain: this._domain,
      loadTimeout: 750,
    });
  }

  get visitor() {
    if (!this._visitor) {
      this.setVisitorInstance();
    }
    return this._visitor;
  }

  get visitorID(): string {
    if (!this.visitor) return '';
    return this.visitor.getMarketingCloudVisitorID();
  }
}
