import { CUSTOM_ELEMENTS_SCHEMA, Inject, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivateStatusComponent } from '@Components/activate-status/activate-status.component';
import { LoaderComponent } from '@Components/loader/loader.component';
import { CountdownWrapperComponent } from '@Components/countdown-wrapper/countdown-wrapper.component';
import { BtlOffersComponent } from '@Components/btl-offers/btl-offers.component';
import { GamificationComponent } from '@Components/gamification/gamification.component';
import { BtlExtrasComponent } from '@Components/btl-extras/btl-extras.component';
import { SpecialsComponent } from '@Components/specials/specials.component';
import { TooltipTextComponent } from '@Components/tooltip-text/tooltip-text.component';
import { BannerRewardsAppComponent } from '@Components/banners/banner-rewards-app/banner-rewards-app.component';
import { NavBarComponent } from '@Components/navbar/nav-bar.component';
import { SharedBannersComponent } from '@Components/content/shared-banners.component';
import { TealiumConfig, TealiumModule } from './modules/tealium-utag.module';
import { environment } from '@Environments/environment';
import { BannerJoinComponent } from './components/banners/banner-join/banner-join.component';

const tealiumConfig: TealiumConfig = environment.tealium;

@NgModule({
  declarations: [
    ActivateStatusComponent,
    LoaderComponent,
    CountdownWrapperComponent,
    BtlOffersComponent,
    GamificationComponent,
    BtlExtrasComponent,
    SpecialsComponent,
    TooltipTextComponent,
    BannerRewardsAppComponent,
    NavBarComponent,
    SharedBannersComponent,
    BannerJoinComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, ReactiveFormsModule, TealiumModule.forRoot(tealiumConfig)],
  exports: [
    CommonModule,
    RouterModule,
    ActivateStatusComponent,
    LoaderComponent,
    CountdownWrapperComponent,
    BtlOffersComponent,
    GamificationComponent,
    BtlExtrasComponent,
    SpecialsComponent,
    TooltipTextComponent,
    BannerRewardsAppComponent,
    NavBarComponent,
    SharedBannersComponent,
    BannerJoinComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [{ provide: 'Window', useValue: window }],
})
export class SharedModule {}
