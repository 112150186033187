import { Inject, Injectable } from '@angular/core';
import { AdobeService } from '@Services/adobe.service';
import { TealiumConfig } from '@Shared/modules/tealium-utag.module';
import { TEALIUM_CONFIG_TOKEN } from './tealium-utag-config';
import { TealiumLinkEvent, TealiumLinkEventActivation, TealiumLinkEventCustom } from './tealium-utag-helper.service';

@Injectable()
export class TealiumUtagService {
  scriptSrc = '';

  // Typically set "noview" flag (no first page automatic view event) to true for Single Page Apps (SPAs)
  constructor(@Inject(TEALIUM_CONFIG_TOKEN) private config: TealiumConfig, private adobe: AdobeService) {
    (window as any).utag_cfg_ovrd = { noview: true };
    (window as any).utag_data = {};

    this.setConfig(config);
  }

  // Generic script loader with callback
  getScript(src: string, callback: () => void) {
    const d = document;
    const fn = () => {};
    const o = { callback: callback || fn };
    let s: any;
    let t: any;

    if (typeof src === 'undefined') {
      return;
    }

    s = d.createElement('script');
    s.language = 'javascript';
    s.type = 'text/javascript';
    s.async = 1;
    s.charset = 'utf-8';
    s.src = src;
    if (typeof o.callback === 'function') {
      if (d.addEventListener) {
        s.addEventListener(
          'load',
          () => {
            o.callback();
          },
          false,
        );
      } else {
        // old IE support
        s.onreadystatechange = function() {
          if (this.readyState === 'complete' || this.readyState === 'loaded') {
            this.onreadystatechange = null;
            o.callback();
          }
        };
      }
    }
    t = d.getElementsByTagName('script')[0];
    t.parentNode.insertBefore(s, t);
  }

  // Config settings used to build the path to the utag.js file
  setConfig(config: { script: string }) {
    if (config.script !== undefined) {
      this.scriptSrc = config.script;
    }
  }

  // Data layer is optional set of key/value pairs
  track(tealiumEvent: string, data?: any) {
    if (this.scriptSrc === '') {
      console.log('Tealium config not set.');
      return;
    }
    if ((window as any).utag === undefined) {
      this.getScript(this.scriptSrc, () => {
        (window as any).utag.track(tealiumEvent, { ...data, 'adobe.ECID': this.adobe.visitorID });
      });
    } else {
      (window as any).utag.track(tealiumEvent, { ...data, 'adobe.ECID': this.adobe.visitorID });
    }
  }

  link(data?: TealiumLinkEvent | TealiumLinkEventActivation | TealiumLinkEventCustom) {
    this.track('link', data);
  }

  getTealiumVisitorId(callback: (string) => void) {
    if ((window as any).utag === undefined) {
      this.getScript(this.scriptSrc, () => {
        callback((window as any).utag.data.tealium_visitor_id);
      });
    } else {
      callback((window as any).utag.data.tealium_visitor_id);
    }
  }

  /**
   * A temp solution to address the issue within the utag.js that
   * it does not pick up hashcrns from the WTM cookie. So for our social
   * flows that don't have hashcrn in URL params, we're hacking it to put
   * hashcrn (after user's identified) in the utag_data for later tealium
   * tracking calls to retrieve
   */
  setUserProfileCRNHash(value: string) {
    (window as any).utag_data['user.ProfileCRNHash'] = value;
  }
}
