// import * as CryptoJS from 'crypto-js';
// import * as SecureStorage from "secure-web-storage";
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '@Environments/environment';

export enum StorageKey {
  AUTH_TOKEN = 'auth-token',
  REFRESH_TOKEN = 'refresh-token',
  AUTH_TOKEN_EXPIRATION_DATE = 'expirationDate',
  REFRESH_TOKEN_EXPIRATION_DATE = 'refreshExpirationDate',
  IS_LOGGED_IN = 'loggedIn',
  APPLE_WALLET = 'apple-wallet',
  CARD_OVERLAY_SHOWN = 'card-overlay-shown',
  USER_KEY = 'username',
  PIN_KEY = 'pin-code',
  BIOMETRIC_ENABLED_KEY = 'biometric-enabled',
  PIN_ENABLED_KEY = 'pin-enabled',
  BWS_SPECIAL_DATE = 'bws-special-date',
  HASH_CRN = 'hash-crn',
  ADOBE_ECID = 'adobe-ecid',
  DISMISSABLE_BOOST_WITH_OFFERS = 'dismissable-boost-offers',
  DISMISSABLE_SCAN_CARD = 'dismissable-scan-your-card',
  DISMISSABLE_CHOOSE_YOUR_REWARDS = 'dismissable-choose-your-rewards',
  DISMISSABLE_NEVER_MISS_OFFER = 'dismissable-never-miss-offer',
  DISMISSABLE_TRACK_POINT = 'dismissable-track-your-point',
  DISMISSABLE_DISCOVER_POSIBILITIES = 'dismissable-discover-possibilities',
  DISMISSABLE_SAVE_FUELS = 'dismissable-save-fuels',
  DISMISSABLE_MORE_INFO = 'dismissable-more-info',
  NOTIFICATION_TOGGLE = 'notification-toggle',
  FIRST_LOGIN = 'first-login',
  HASH_CRN_OAP = 'WTM',
}

/**
 * StorageHelper
 */
@Injectable({ providedIn: 'root' })
export class StorageHelper {
  /**
   * Creates an instance of storage helper.
   */
  constructor(private cookieService: CookieService) {}

  /**
   * Sets item to localStorage or secure cookie
   * @param key key
   * @param value value
   */
  setItem(key: StorageKey, value: string, secure: boolean = true) {
    if (secure) {
      if (environment.production) {
        this.cookieService.set(
          key,
          value,
          100,
          '/',
          window && window.location ? window.location.hostname : '',
          true,
          'Lax',
        );
      } else {
        // To create cookie while working on localhost
        this.cookieService.set(key, value, 100, '/', window.location.hostname, null, null);
      }
    } else {
      localStorage.setItem(key, value);
    }
  }

  /**
   * Gets item from localStorage or secure cookie
   * @param key key
   * @returns corresponding value
   */
  getItem(key: StorageKey, secure: boolean = true) {
    if (secure) {
      return this.cookieService.get(key);
    } else {
      return localStorage.getItem(key);
    }
  }

  /**
   * Removes item from localStorage or secure cookie
   * @param key key to remove
   */
  removeItem(key: StorageKey, secure: boolean = true) {
    if (secure) {
      this.cookieService.set(
        key,
        '',
        new Date(0),
        '/',
        window && window.location ? window.location.hostname : '',
        environment.production,
        'Lax',
      );
    } else {
      localStorage.removeItem(key);
    }
  }

  removeStaleHashCRNFromStorage(hashCrn: string) {
    if (hashCrn && this.getItem(StorageKey.HASH_CRN_OAP) !== hashCrn) {
      this.removeItem(StorageKey.HASH_CRN_OAP);
    }
  }

  setHashCrnCookie(hashCrn: string) {
    this.setItem(StorageKey.HASH_CRN_OAP, hashCrn);
  }

  getHashCrnCookie(): string {
    return this.getItem(StorageKey.HASH_CRN_OAP);
  }

  removeHashCrnCookie() {
    this.removeItem(StorageKey.HASH_CRN_OAP);
  }
}
