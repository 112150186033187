import { ModuleWithProviders, NgModule } from '@angular/core';
import { AdobeService } from '@Services/adobe.service';
import { TEALIUM_CONFIG_TOKEN } from '@Services/oap/tealium-utag-config';
import { TealiumUtagService } from '../service/oap/tealium-utag.service';

export interface TealiumConfig {
  script: string;
}

@NgModule()
export class TealiumModule {
  static forRoot(config: TealiumConfig): ModuleWithProviders {
    return {
      ngModule: TealiumModule,
      providers: [
        TealiumUtagService,
        AdobeService,
        {
          provide: TEALIUM_CONFIG_TOKEN,
          useValue: config,
        },
      ],
    };
  }
}
