export function isHashCrnGroupA(hashCrn: string) {
  const groupA = ['0', '1', '2', '3', '4', '5', '6', '7'];
  const firstChar = hashCrn.charAt(0);
  return groupA.includes(firstChar);
}

export function isTealiumVisitorIdGroupA(visitorId: string) {
  const groupA = ['0', '1', '2', '3', '4', '5', '6', '7'];
  const charToTest = visitorId.charAt(9);
  return groupA.includes(charToTest);
}
