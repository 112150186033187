<div class="app-container body-wrapper is-light">
  <div>
    <router-outlet></router-outlet>
    <router-outlet name="offerDetailsModal"></router-outlet>
  </div>

  <app-loader [show]="isLoading$ | async"></app-loader>

  <rds-modal simple colour="is-light" [visible]="isOffline" (close)="onCloseOfflineModal()">
    <div class="has-text-centered pb-6 mb-6 pt-6 mt-6">
      <img class="mt-6 pt-6" src="/assets/images/plug.svg" width="100%" alt="Image of a plug" />
      <h2 class="mb-6 mt-6 pt-6">Oh-oh!</h2>
      <p class="mb-6">
        Your internet connection appears to be offline.<br />
        Please check your connection and try again.
      </p>
    </div>
  </rds-modal>
  <div *ngIf="isGamificationCampaignPreview" class="campaign-preview-banner">
    <span>You're viewing a content preview page!</span>
  </div>
</div>
