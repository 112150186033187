import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { routerTransition } from '../animations';
import { CardBalance, UserState } from '@State/user.state';
import { Select, Store } from '@ngxs/store';
import { LoaderService } from '@Services/loader.service';
import { delay } from 'rxjs/operators';
import { Observable, Subject, Subscription } from 'rxjs';
import { ResetUserDetails, UpdateHashCRN } from '@State/user.actions';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadingScriptService } from '@Services/lazy-loading-script.service';
import { environment } from '@Environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { QUERY_PARAMS } from '@Services/oap/tealium-utag-helper.service';

declare var dT_;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerTransition],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit, OnDestroy {
  @Select(UserState.getUserBalance) userBalance$: Observable<CardBalance>;
  @Select(UserState.getHashCrn) hashCrn$: Observable<string>;

  isLoading$ = this.loaderService.loaderVisibility.pipe(delay(0));
  isOffline = false;
  firstName = null;
  subscriptions: Subscription[] = [];
  isGamificationCampaignPreview = false;

  @HostListener('window:offline', ['$event'])
  onOffline() {
    this.isOffline = true;
  }

  @HostListener('window:online', ['$event'])
  onOnline() {
    this.isOffline = false;
  }

  onCloseOfflineModal() {
    this.isOffline = false;
  }

  constructor(
    private loaderService: LoaderService,
    private store: Store,
    private route: ActivatedRoute,
    private lazyLoadService: LazyLoadingScriptService,
    private http: HttpClient,
  ) {
    if ((window as any).utag === undefined)
      this.lazyLoadService.loadScript(environment.tealium.script, true, 'text/javascript').subscribe();

    if (typeof dT_ !== 'undefined' && dT_.initAngularNg) {
      dT_.initAngularNg(http, HttpHeaders);
    }

    this.lazyLoadService.loadScript(environment.dynatrace.script, true, 'text/javascript', 'anonymous').subscribe();
  }

  ngOnInit() {
    this.store.dispatch([new ResetUserDetails()]);

    this.subscriptions.push(
      this.route.queryParams.subscribe(params => {
        this.isGamificationCampaignPreview =
          params[QUERY_PARAMS.GAMIFICATION_CAMPAIGN_PREVIEW] === 'true' ||
          params[QUERY_PARAMS.GAMIFICATION_CAMPAIGN_PREVIEW] === true;
      }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  getHashCRNFromURLAndStoreItInState() {
    if (this.route && this.route.snapshot && this.route.snapshot.queryParams) {
      const crn = this.route.snapshot.queryParams['crn'];
      if (crn) {
        this.store.dispatch([new UpdateHashCRN(crn)]);
      }
    }
  }
}
