import { Action, Selector, State, StateContext } from '@ngxs/store';
import { HttpClient } from '@angular/common/http';
import {
  GetUserBalance,
  GetUserBalanceAndFirstName,
  GetUserFirstName,
  ResetUserDetails,
  ResetUserErrors,
  UpdateHashCRN,
  UserBalanceFailed,
  UserBalanceSuccess,
  UserDPULoginSuccess,
  UserFirstNameFailed,
  UserFirstNameSuccess,
} from './user.actions';
import { AuthStatus, BalanceStatus, FirstNameStatus } from '@Model/oap/user.model';
import { catchError, tap } from 'rxjs/operators';
import { IdentifierType, OfferType } from '@Model/oap/offer.model';
import { HeaderService } from '@Services/oap/header.service';
import { ApiServiceHelper } from '@OAP/Utils/api-service-helper';
import { StorageHelper, StorageKey } from '@OAP/Utils/storage-helper';
import moment from 'moment';
import { isHashCrnGroupA } from '@OAP/Utils/ab-testing-helper';
import { TealiumUtagHelperService } from '@Services/oap/tealium-utag-helper.service';

export interface CardBalance {
  lifetimeRedeemed: number;
  lifetimeEarned: number;
  currentCreditBalance: number;
  availableVouchers: number;
  currentVoucherBalance: number;
  creditsRequiredForNextVoucher: number;
  voucherAvailableDate: any;
  currentFuelDiscounts: number;
  lastNinetyDaysSavings: number;
}

export interface UserStateModel {
  userFirstName: string;
  userFirstNameStatus: string;
  cardBalance: CardBalance;
  userBalanceStatus: string;
  authStatus: string;
  authToken: string;
  authTokenExpiry: string;
  attemptify: any;
  hashCrn: string;
}

/**
 * Initial state for user
 */
@State<UserStateModel>({
  name: 'user',
  defaults: {
    userFirstName: null,
    userFirstNameStatus: FirstNameStatus.INIT,
    cardBalance: null,
    userBalanceStatus: BalanceStatus.INIT,
    authStatus: AuthStatus.INIT,
    authToken: null,
    authTokenExpiry: null,
    attemptify: {},
    hashCrn: null,
  },
})
export class UserState {
  @Selector()
  static getUserFirstName(state: UserStateModel) {
    return state.userFirstName;
  }

  @Selector()
  static userAuthStatus(state: UserStateModel) {
    return state.authStatus;
  }

  @Selector()
  static getUserBalance(state: UserStateModel) {
    return state.cardBalance;
  }

  @Selector()
  static getHashCrn(state: UserStateModel) {
    return state.hashCrn;
  }

  @Selector()
  static getUser(state: UserStateModel) {
    return state;
  }

  @Selector()
  static authToken(state: UserStateModel): string | null {
    return state.authToken;
  }

  @Selector()
  static isAuthenticated(state: UserStateModel): boolean {
    return !!state.authToken || !!state.hashCrn;
  }

  @Selector()
  static isGroupA(state: UserStateModel): boolean {
    if (state.hashCrn) {
      return isHashCrnGroupA(state.hashCrn);
    }
    return false;
  }

  @Action(ResetUserDetails)
  resetUserDetails({ patchState }: StateContext<UserStateModel>) {
    patchState({
      userFirstName: null,
      userFirstNameStatus: FirstNameStatus.INIT,
      cardBalance: null,
      userBalanceStatus: BalanceStatus.INIT,
      authStatus: AuthStatus.INIT,
      hashCrn: null,
    });
  }

  @Action(UpdateHashCRN)
  updateHashCrn({ patchState }: StateContext<UserStateModel>, { hashCrn }: UpdateHashCRN) {
    patchState({ hashCrn });
  }

  @Action(ResetUserErrors)
  resetUserErrors({ patchState }: StateContext<UserStateModel>) {
    patchState({
      authStatus: AuthStatus.INIT,
    });
  }

  @Action(GetUserBalanceAndFirstName)
  getUserBalanceAndFirstName({ dispatch }: StateContext<UserStateModel>, payload: any) {
    if (payload.type === OfferType.OFFER_ID) {
      dispatch([
        new GetUserBalance(payload.type, payload.identifier),
        new GetUserFirstName(payload.type, payload.identifier),
      ]);
    }
    if (payload.type === OfferType.COMM_ID) {
      dispatch([new GetUserFirstName(payload.type, payload.identifier)]);
    }
  }

  @Action(GetUserFirstName)
  getUserFirstName({ patchState, dispatch }: StateContext<UserStateModel>, action: any) {
    patchState({
      userFirstNameStatus: FirstNameStatus.LOADING,
    });

    return this.http
      .get(
        ApiServiceHelper.generateOamUrl('getFirstName'),
        this.updateHeaderForUOID(this.header.getHeader(action.identifier)),
      )
      .pipe(
        tap(value => {
          return dispatch(new UserFirstNameSuccess(value, action.identifier));
        }),
        catchError(() => {
          return dispatch(new UserFirstNameFailed(action.identifier));
        }),
      );
  }

  @Action(UserFirstNameSuccess)
  firstNameLoadSuccess({ patchState }: StateContext<UserStateModel>, payload: any) {
    let userFirstName = '';
    let userFirstNameStatus = FirstNameStatus.LOADING;
    let hashCrn = '';

    if (!!payload.firstName && !!payload.firstName.first_name) {
      userFirstName = payload.firstName.first_name;
      userFirstNameStatus = FirstNameStatus.HAS_NAME;
      if (!!payload.firstName.hashed_crn) {
        // if hashed CRN is returned save it as a cookie
        this.storageHelper.setItem(StorageKey.HASH_CRN_OAP, payload.firstName.hashed_crn);
        hashCrn = payload.firstName.hashed_crn;
      }
    } else {
      userFirstName = '';
      this.storageHelper.removeItem(StorageKey.HASH_CRN_OAP, true);
      if (payload.identifier.type === IdentifierType.OFFER_ID_HASH_CRN) {
        userFirstNameStatus = FirstNameStatus.FAILED_COOKIE;
      } else {
        userFirstNameStatus = FirstNameStatus.FAILED;
      }
    }

    patchState({
      userFirstName,
      userFirstNameStatus,
      hashCrn,
      authStatus: AuthStatus.SUCCESS,
    });
  }

  @Action(UserFirstNameFailed)
  firstNameLoadFailed({ patchState }: StateContext<UserStateModel>, { payload }: any) {
    patchState({
      userFirstName: '',
      userFirstNameStatus:
        !!payload && payload.identifier.type === IdentifierType.OFFER_ID_HASH_CRN
          ? FirstNameStatus.FAILED_COOKIE
          : FirstNameStatus.FAILED,
      authStatus: AuthStatus.FAILED,
      hashCrn: '',
    });
  }

  @Action(GetUserBalance)
  getUserBalance({ patchState, dispatch }: StateContext<UserStateModel>, action: any) {
    patchState({
      userBalanceStatus: BalanceStatus.LOADING,
    });

    return this.http
      .get(
        ApiServiceHelper.generateOamUrl('getCardBalance'),
        this.updateHeaderForUOID(this.header.getHeader(action.identifier)),
      )
      .pipe(
        tap(value => {
          return dispatch(new UserBalanceSuccess(value));
        }),
        catchError(() => {
          return dispatch(new UserBalanceFailed());
        }),
      );
  }

  @Action(UserBalanceSuccess)
  userBalanceLoadSuccess({ patchState }: StateContext<UserStateModel>, { userBalance }: any) {
    patchState({
      cardBalance: userBalance,
    });
  }

  @Action(UserBalanceFailed)
  userBalanceLoadFailed({ patchState }: StateContext<UserStateModel>) {
    patchState({
      cardBalance: null,
    });
  }

  @Action(UserDPULoginSuccess)
  userDPULoginSuccess({ patchState }: StateContext<UserStateModel>, action: UserDPULoginSuccess) {
    patchState({
      authToken: action.wxLoginToken.bearer,
      authTokenExpiry: moment()
        .add(+action.wxLoginToken.bearerExpiredInSeconds)
        .format('YYYY-MM-DDTHH:mm:ss'),
      authStatus: AuthStatus.SUCCESS,
    });
  }

  constructor(private http: HttpClient, private header: HeaderService, private storageHelper: StorageHelper) {}

  updateHeaderForUOID(header) {
    if (header.headers) {
      header.headers = header.headers.delete('uoid');
    }
    return header;
  }
}
