export interface Offer {
  firstName: any;
  userFirstNameStatus: string;
}

export enum FirstNameStatus {
  INIT = 'INIT',
  LOADING = 'LOADING',
  HAS_NAME = 'HAS_NAME',
  NO_NAME = 'NO_NAME',
  FAILED_COOKIE = 'FAILED_COOKIE',
  FAILED = 'FAILED',
}

export enum AuthStatus {
  INIT = 'INIT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  MULTIPLE_CARDS = 'MULTIPLE_CARDS',
  FAILED = 'FAILED',
}

export enum BalanceStatus {
  INIT = 'INIT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum IdentificationState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  COOKIE_FOUND = 'COOKIE_FOUND',
  HAS_VALID_COOKIE = 'HAS_VALID_COOKIE',
  FAILED = 'FAILED',
}

export class BalanceData {
  public static data: Array<any> = [
    {
      type: 'REWARDS_POINTS',
      dataMap: 'currentCreditBalance',
      data: {
        icon: 'woolies.svg',
        value: '1800',
        text: 'Points',
        newLine: true,
      },
    },
    {
      type: 'QUANTAS_POINTS',
      dataMap: 'qffVoucherBalance',
      data: {
        icon: 'aircraft.svg',
        value: '$20',
        text: 'to convert to Quantas points',
      },
    },
    {
      type: 'REWARDS_CHRISTMAS',
      data: {
        icon: 'christmas.svg',
        value: '$20',
        text: 'banked for Christmas',
      },
    },
    {
      type: 'REWARDS_AUTO_SAVE',
      data: {
        icon: 'save-10.svg',
        value: '$10',
        text: 'to save automatically',
      },
    },
    {
      type: 'FUEL_VOUCHERS',
      dataMap: 'currentFuelDiscounts',
      data: {
        icon: 'fuel.svg',
        value: '2x',
        text: 'Fuel vouchers',
        newLine: true,
      },
    },
    {
      type: 'REWARDS_REDEEMED',
      dataMap: 'lifetimeRedeemed',
      data: {
        icon: 'piggy-bank.svg',
        value: '$1,400',
        text: 'Redeemed',
        newLine: true,
      },
    },
  ];
}
