import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GAMIFICATION_PATH } from '@Shared/constants';

const routes: Routes = [
  {
    path: 'comm-id',
    loadChildren: () => import('./activate-comm-id/activate-comm-id.module').then(m => m.ActivateCommIdModule),
  },
  {
    path: 'offer',
    loadChildren: () => import('./activate-offer/activate-offer.module').then(m => m.ActivateOfferModule),
  },
  {
    path: 'offer-id',
    loadChildren: () => import('./activate-offer-id/activate-offer-id.module').then(m => m.ActivateOfferIdModule),
  },
  {
    path: 'promo',
    loadChildren: () => import('./activate-promo-code/activate-promo-code.module').then(m => m.ActivatePromoCodeModule),
  },
  {
    path: 'weekly-boosters',
    loadChildren: () =>
      import('./activate-weekly-boosters/activate-weekly-boosters.module').then(m => m.ActivateWeeklyBoostersModule),
  },
  {
    path: 'view-offers',
    loadChildren: () => import('./view-offers/view-offers.module').then(m => m.ViewOffersModule),
  },
  {
    path: GAMIFICATION_PATH,
    loadChildren: () => import('./gamifications/gamifications.module').then(m => m.GamificationsModule),
  },
  {
    path: 'preview-cms-content',
    loadChildren: () => import('./preview-cms-content/preview-cms-content.module').then(m => m.PreviewCmsContentModule),
  },
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled', useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
