export interface Offer {
  content: any;
  viewState: string;
  offerType: string;
  offerId: string;
}

export enum OfferContentView {
  INIT = 'INIT',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  FAILED = 'FAILED',
  BLOCKED = 'BLOCKED',
}

export enum OfferProgressState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum PromoCodeState {
  INIT = 'INIT',
  LOADING = 'LOADING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum OfferType {
  COMM_ID = 'COMM_ID',
  OFFER_ID = 'OFFER_ID',
  PROMO_UNIQUE = 'PROMO_UNIQUE',
  PROMO_GENERIC = 'PROMO_GENERIC',
}

export enum OfferActivationStatus {
  INIT = 'INIT',
  LOADING = 'LOADING',
  ACTIVATED = 'ACTIVATED',
  ALREADY_ACTIVATED = 'ALREADY_ACTIVATED',
  FAILED = 'FAILED',
  BLOCKED = 'BLOCKED',
}

export enum IdentifierType {
  COMM_ID = 'COMM_ID',
  OFFER_ID = 'OFFER_ID',
  OFFER_ID_HASH_CRN = 'OFFER_ID_HASH_CRN',
  OFFER_ID_LCN = 'OFFER_ID_LCN',
  OFFER_ID_EMAIL_PASSWORD = 'OFFER_ID_EMAIL_PASSWORD',
  OFFER_ID_EMAIL_DOB = 'OFFER_ID_EMAIL_DOB',
  OFFER_ID_EMAIL_PHONE = 'OFFER_ID_EMAIL_PHONE',
  OFFER_ID_EMAIL = 'OFFER_ID_EMAIL',
  OFFER_ID_EMAIL_TOKEN = 'OFFER_ID_EMAIL_TOKEN',
  OFFERS_BTL = 'OFFERS_BTL',
  GET_FIRST_NAME_BY_CRN = 'GET_FIRST_NAME_BY_CRN'
}

export enum ActivationType {
  ACTIVATION_TYPE_P = 'P',
  ACTIVATION_TYPE_M = 'M',
  ACTIVATION_TYPE_S = 'S',
  ACTIVATION_TYPE_X = 'X',
}

export class OfferMessages {
  public static DEFAULT_CONTENT_ERROR = `
    <div class="text-2xl font-sansbold">Boost error!</div>
    <div class="my-3">We cannot boost this offer now. Please try again later.</div>`;

  public static DEFAULT_ACTIVATION_SUCCESS = `Your offer is boosted`;
  public static DEFAULT_ACTIVATION_STATUS = `
    <div class="text-2xl font-sansbold">Boost error!</div>
    <div class="my-3">We cannot boost this offer now. Please try again later.</div>`;
  public static DEFAULT_ACTIVATION_STATUS_ERROR = `
    <div class="text-2xl font-sansbold">Boost error!</div>
    <div class="my-3">We cannot boost this offer now. Please try again later.</div>`;
  public static LOGIN_MESSAGE = `<div class="my-1"> you may have boosted this offer, provide your details below to check.</div>`;
}

// export interface OfferRequestData {
//   uuid_comm_id:         string;
//   uoid:                 string;
//   hashed_crn:           string;
//   loyalty_card_number:  string;
//   email:                string;
//   date_of_birth:        string;
//   promo_code:           string;
//   mobile:               string;
//   crn:                  string;
// }

export interface LinkedOfferContent {
  offer_id: any;
  week_number: number;
  start_date: string;
  end_date: string;
  description: string;
}

export interface LinkedOfferProgress {
  offer_id?: any;
  offer_alloc_start_ts?: string;
  offer_alloc_end_ts?: string;
  offer_state: string;
  offer_status: string;
  offer_wk_no?: number;
}

export interface OfferProgress {
  activation_type: string;
  linked_offers: LinkedOfferProgress[];
  offer_id: number;
  offer_linked_ind?: boolean;
}
