import { Injectable } from '@angular/core';
import { IdentifierType } from '@Model/oap/offer.model';
import { environment } from '@Environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor() {}

  getHeader(identifier: any, channel?: string): { headers: HttpHeaders } {
    let headers: HttpHeaders;
    switch (identifier.type) {
      case IdentifierType.COMM_ID:
        headers = new HttpHeaders({
          client_id: environment.oapApiClientId,
          uuid_comm_id: identifier.data.comm_id,
        });
        break;
      case IdentifierType.OFFER_ID:
        headers = new HttpHeaders({});
        break;
      case IdentifierType.OFFER_ID_HASH_CRN:
        headers = new HttpHeaders({
          client_id: environment.oapApiClientId,
          uoid: identifier.data.offer_id,
          hashed_crn: identifier.data.hashed_crn,
        });
        break;

      case IdentifierType.OFFER_ID_LCN:
        headers = new HttpHeaders({
          client_id: environment.oapApiClientId,
          uoid: identifier.data.offer_id,
          loyalty_card_number: identifier.data.loyalty_card_number,
        });
        break;

      case IdentifierType.OFFER_ID_EMAIL_PHONE:
        headers = new HttpHeaders({
          client_id: environment.oapApiClientId,
          uoid: identifier.data.offer_id,
          mobile: identifier.data.phone,
          email: identifier.data.email,
        });
        break;

      case IdentifierType.OFFER_ID_EMAIL_DOB:
        headers = new HttpHeaders({
          client_id: environment.oapApiClientId,
          uoid: identifier.data.offer_id,
          date_of_birth: identifier.data.dob,
          email: identifier.data.email,
        });
        break;

      case IdentifierType.OFFER_ID_EMAIL_TOKEN:
        headers = new HttpHeaders({
          client_id: environment.oapApiClientId,
          uoid: identifier.data.offer_id,
          Authorization: 'Bearer ' + identifier.data.bearer,
          email: identifier.data.email,
        });
        break;

      case IdentifierType.OFFERS_BTL:
        headers = new HttpHeaders({
          client_id: environment.oapApiClientId,
          Accept: 'application/json, text/plain, */*',
        });
        break;

      case IdentifierType.GET_FIRST_NAME_BY_CRN:
      headers = new HttpHeaders({
        client_id: environment.oapApiClientId,
        hashed_crn: identifier.data.hashed_crn,
      });
        break;

      default:
        break;
    }

    // add channel
    if (channel) {
      headers = headers.append('channel', channel);
    }

    return { headers };
  }
}
