import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { TealiumUtagService } from './tealium-utag.service';
import { HASH_CRN_TOKEN, TEALIUM_PAGE_NAME_TOKEN } from './tealium-utag-config';
import { StorageKey } from '@OAP/Utils/storage-helper';
import { TealiumChannel } from '@DS/Utils/tealium-tracking/tealium-tracking.interface';
import { AddToCalendarToggleState, Extra, OfferID } from '@DS/Tokens/components-common';
import { TealiumTracking } from '@DS/Utils/tealium-tracking/tealium-tracking';
import { environment } from '@Environments/environment';

export interface TealiumViewEvent {
  tealium_event: string;
  'page.Application': string;
  'page.Name': string;
  'page.Type': string;
  'user.ProfileLoggedInStatus': string;
}
export interface TealiumLinkEvent {
  tealium_event: string;
  'user.ProfileCRNHash'?: string;
  'user.ProfileLoggedInStatus'?: string;
  'campaign.Code'?: string | string[];
  'campaign.End'?: string;
  'campaign.OfferId'?: string | string[];
  'campaign.Status'?: string;
  'campaign.Start'?: string;
  'campaign.Stream'?: string;
  'campaign.Channel'?: string;
  'page.Name'?: string;
}
export interface TealiumLinkEventActivation extends TealiumLinkEvent {
  'campaign.UOID'?: string;
  'campaign.UUID'?: string;
  'event.CurrUserAction'?: string;
  'event.CurrUserActionChannel'?: string;
  'offer.RTLCampaignID'?: string[]; // Array of campaign ids
  'offer.RTLAccountID'?: string[]; // Array of account ids
  'offer.RTLCampaignCode'?: string[]; // Array of campaign codes
}
export interface TealiumLinkEventCustom extends TealiumLinkEvent {
  'event.Action'?: string;
  'event.Category'?: string;
  'event.Label'?: string;
  'event.Value'?: string;
}

export enum PATH_PARAMS {
  GAMIFICATION_CAMPAIGN_SLUG = 'campaign-slug',
}

export enum QUERY_PARAMS {
  HASH_CRN = 'hash_crn',
  ONBOARDING_MODAL = 'onboarding-modal',
  UTM_PREFIX = 'utm_',
  UTM_MEDIUM = 'utm_medium',
  OFFER_IDS = 'offer_ids',
  SHOW_XMAS_BANNER = 'show_xmas_banner',
  CRN = 'crn',
  SHOW_TIGER = 'tiger',
  JOIN_NOW_PROMO_CTA = 'join-now-promo-cta',
  ENABLE_DEEP_LINKING = 'enable-deep-linking',
  GAMIFICATION_ID = 'gamification_id',
  GAMIFICATION_STATUS = 'gamification_status',
  GAMIFICATION_CAMPAIGN_SLUG = 'gamification_campaign_slug',
  GAMIFICATION_CAMPAIGN_PREVIEW = 'gamification_campaign_preview',
  WEBVIEW = 'webview',
  REWARDS_BRAND = 'rewards_brand',
  CAMPAIGN_CODE = 'campaign-id',
  ID = 'id',
  TYPE = 'type',
  WELCOMING_CAMPAIGN_CODE = 'campaign_code',
}

@Injectable({
  providedIn: 'root',
})
export class TealiumUtagHelperService {
  rdsTealiumTracking: TealiumTracking;

  constructor(
    @Inject(TEALIUM_PAGE_NAME_TOKEN) private pageName: string,
    private tealium: TealiumUtagService,
    @Inject(HASH_CRN_TOKEN) private hashCrnOnInit?: string,
  ) {
    if (this.hashCrnOnInit) {
      const cookieExists = document.cookie
        .split(';')
        .some(item => item.trim().startsWith(`${StorageKey.HASH_CRN_OAP}=`));
      let cookieHashCrn = '';
      if (cookieExists) {
        cookieHashCrn = document.cookie
          .split('; ')
          .find(row => row.startsWith(`${StorageKey.HASH_CRN_OAP}=`))
          .split('=')[1];

        // clear cookie if different from injected hash crn
        if (cookieHashCrn !== this.hashCrnOnInit) {
          document.cookie = StorageKey.HASH_CRN_OAP + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        }
      }
    }

    this.rdsTealiumTracking = new TealiumTracking(TealiumChannel.oap, this.hashCrnOnInit, !environment.production);
  }

  link(event: TealiumLinkEvent | TealiumLinkEventActivation | TealiumLinkEventCustom) {
    this.tealium.link({
      'page.Name': this.pageName,
      'user.ProfileLoggedInStatus': 'No',
      ...event,
    });
  }

  trackActivationSuccess(
    uoid: string,
    uuid: string,
    channel: string,
    id: OfferID,
    startDate: string,
    endDate: string,
    btlOfferCampaign?: string,
    btlOfferStatus?: string,
  ) {
    // legacy offers have been deprecated
  }

  /**
   * Deprecated: this should ONLY be used for legacy auto offer boosting journey, e.g. offer.state called by offer-if or comm-id flow
   */
  trackActivationFail(
    uoid: string,
    uuid: string,
    channel: string,
    id: OfferID,
    startDate: string,
    endDate: string,
    btlOfferCampaign?: string,
    btlOfferStatus?: string,
  ) {
    // legacy offers have been deprecated
  }

  trackOfferLoadError(headerTitle: string) {
    this.rdsTealiumTracking.trackOfferErrorImpression(headerTitle, false);
  }

  trackCtaClick(id: string, startDate: string, endDate: string, status: string) {
    const campaign = this.getCampaign();
    this.link({
      'event.Action': 'click',
      'event.Category': 'offer',
      'event.Label': 'cta clicked',
      'event.Value': '5',
      tealium_event: 'rw_custom_event',
      'campaign.Code': campaign ? campaign : '',
      'campaign.Stream': campaign ? campaign.substr(0, 3) : '',
      'campaign.OfferId': id.toString(),
      ...(moment(startDate).isValid() && { 'campaign.Start': this.formatDate(startDate) }),
      ...(moment(endDate).isValid() && { 'campaign.End': this.formatDate(endDate) }),
      'campaign.Status': status,
    });
  }

  trackRewardsBannerClick(heading: string, ctaLabel: string) {
    this.rdsTealiumTracking.trackBannerClick(heading, ctaLabel);
  }

  trackRewardsBannerImpression(heading: string) {
    this.rdsTealiumTracking.trackBannerImpression(heading);
  }

  trackResolvePromoCodeSuccess(uoid: string, uuid: string, promoCode: string) {
    const campaign = this.getCampaign();
    this.link({
      'campaign.UOID': uoid,
      'campaign.UUID': uuid,
      'event.Action': 'click',
      'event.Category': 'continue',
      'event.Label': 'resolve promo code success',
      'event.Value': promoCode,
      tealium_event: 'rw_custom_event',
      'campaign.Code': campaign ? campaign : '',
      'campaign.Stream': campaign ? campaign.substr(0, 3) : '',
    });
  }

  trackResolvePromoCodeFailure(promoCode: string) {
    const campaign = this.getCampaign();
    this.link({
      'campaign.Code': campaign ? campaign : '',
      'campaign.Stream': campaign ? campaign.substr(0, 3) : '',
      'event.Action': 'click',
      'event.Category': 'continue',
      'event.Label': 'resolve promo code failure',
      'event.Value': promoCode,
      tealium_event: 'rw_custom_event',
    });
  }

  trackFooterClick(footerItem: string, hashCrn?: string) {
    const campaign = this.getCampaign();
    this.link({
      'campaign.Code': campaign ? campaign : '',
      'campaign.Stream': campaign ? campaign.substr(0, 3) : '',
      'event.Action': 'click',
      'event.Category': 'footer',
      'event.Label': footerItem + ' clicked',
      'event.Value': '5',
      tealium_event: 'rw_custom_event',
      'user.ProfileCRNHash': hashCrn, // temp hack, see this.tealium.setUserProfileCRNHash method
    });
  }

  trackSpecialsButtonClick(brand: string, hashCrn?: string) {
    const campaign = this.getCampaign();
    this.link({
      'campaign.Code': campaign ? campaign : '',
      'campaign.Stream': campaign ? campaign.substr(0, 3) : '',
      'event.Action': 'click',
      'event.Category': 'specials',
      'event.Label': 'shop now clicked ' + brand,
      'event.Value': '5',
      tealium_event: 'rw_custom_event',
      'user.ProfileCRNHash': hashCrn, // temp hack, see this.tealium.setUserProfileCRNHash method
    });
  }

  trackFavouriteCtaClick(label: string, url: string, hashCrn?: string) {
    const campaign = this.getCampaign();
    this.link({
      'campaign.Code': campaign ? campaign : '',
      'campaign.Stream': campaign ? campaign.substr(0, 3) : '',
      'event.Action': 'click',
      'event.Category': 'specials',
      'event.Label': `${label}: ${url}`,
      'event.Value': '5',
      tealium_event: 'rw_custom_event',
      'user.ProfileCRNHash': hashCrn, // temp hack, see this.tealium.setUserProfileCRNHash method
    });
  }

  trackStickyTabSelection(tab: string, hashCrn?: string) {
    const campaign = this.getCampaign();
    this.link({
      'campaign.Code': campaign ? campaign : '',
      'campaign.Stream': campaign ? campaign.substr(0, 3) : '',
      'event.Action': 'click',
      'event.Category': 'sticky tab selected',
      'event.Label': tab,
      'event.Value': '5',
      tealium_event: 'rw_custom_event',
      'user.ProfileCRNHash': hashCrn, // temp hack, see this.tealium.setUserProfileCRNHash method
    });
  }

  trackExtrasCard(extra: Extra, headerTitle: string) {
    this.rdsTealiumTracking.trackExtraItemClick(extra, headerTitle, false);
  }

  trackExtrasCtaClick(extra: Extra, headerTitle: string) {
    this.rdsTealiumTracking.trackExtraItemClick(extra, headerTitle, true);
  }

  trackExtraCardSuccessLoad(extra: Extra, headerTitle: string) {
    this.rdsTealiumTracking.trackExtraCardSuccessLoad(extra, headerTitle);
  }

  trackExtrasDetailsView(extra: Extra, headerTitle: string) {
    this.rdsTealiumTracking.trackExtraItemDetailsView(extra, headerTitle);
  }

  trackCarouselSwipe(headerTitle: string, swipedToEnd: boolean) {
    this.rdsTealiumTracking.trackCarouselSwipe(headerTitle, swipedToEnd);
  }

  trackRenderingLayerClickEvent(
    action: string,
    category: string,
    label: string,
    campaignCode: string,
    offerId: OfferID,
    uoid: string,
    commId: string,
    offerStatus: string,
  ) {
    const pageName = 'ww-rw:offers:o';
    this.link({
      tealium_event: 'rw_custom_event',
      'event.Action': action,
      'event.Category': category,
      'event.Label': label,
      'campaign.Code': campaignCode,
      'campaign.OfferId': String(offerId),
      'campaign.UOID': uoid,
      'campaign.UUID': commId,
      'campaign.Status': offerStatus,
      'page.Name': pageName,
    });
  }

  trackTigerOfferDetailsView(campaign: string, offerId: string, offerStatus: string) {
    this.link({
      'campaign.Code': campaign,
      'campaign.Stream': campaign.substr(0, 3),
      'event.Action': 'click',
      'event.Category': 'Tiger offer',
      'event.Label': 'tiger offer details viewed',
      'event.Value': '5',
      tealium_event: 'rw_custom_event',
      'campaign.OfferId': offerId,
      'campaign.Status': offerStatus,
    });
  }

  trackSocialLoginModal(label: string) {
    const campaign = this.getCampaign();
    this.link({
      'campaign.Code': campaign ? campaign : '',
      'campaign.Stream': campaign ? campaign.substr(0, 3) : '',
      'event.Action': 'modal view',
      'event.Category': 'login',
      'event.Label': label,
      'event.Value': '5',
      tealium_event: 'rw_custom_event',
    });
  }

  trackSocialLoginResponse(label: string, action: string, hashCrn?: string) {
    const campaign = this.getCampaign();
    this.link({
      'campaign.Code': campaign ? campaign : '',
      'campaign.Stream': campaign ? campaign.substr(0, 3) : '',
      'event.Action': action,
      'event.Category': 'login',
      'event.Label': label,
      'event.Value': '5',
      tealium_event: 'rw_custom_event',
      'user.ProfileCRNHash': hashCrn, // temp hack, see this.tealium.setUserProfileCRNHash method
    });
  }

  getCampaign() {
    let queryString = Boolean(window.location.search) ? window.location.search : window.location.hash;
    if (queryString.split('?').length > 1) {
      queryString = `?${queryString.split('?')[1]}`;
    }
    const queryParams = new URLSearchParams(queryString);
    return queryParams.has('utm_campaign') ? queryParams.get('utm_campaign') : '';
  }

  formatDate(dateString: string) {
    return moment(dateString).format('YYYYMMDD');
  }

  setHashCrn(value: string) {
    this.tealium.setUserProfileCRNHash(value);
  }

  getTealiumVisitorId(callback: (string) => void) {
    return this.tealium.getTealiumVisitorId(callback);
  }

  trackActivateStatusAddToCalendarToggleClick(toggleState: string) {
    this.rdsTealiumTracking.trackOfferAddToCalendarToggleClick(
      toggleState as AddToCalendarToggleState,
      'Activation message',
      this.pageName,
    );
  }

  trackActivateStatusAddToCalendarItemClick(itemName: string) {
    this.rdsTealiumTracking.trackOfferAddToCalendarItemClick(itemName, 'Activation message', this.pageName);
  }

  trackPageView() {
    this.rdsTealiumTracking.trackPageView();
  }
}
