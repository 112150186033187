export class UpdateHashCRN {
  static readonly type = '[User] Update hash crn';
  constructor(public hashCrn: string) {}
}

export class GetUserFirstName {
  static readonly type = '[User] Get User First Name';
  constructor(public type: string, public identifier: any) {}
}

export class UserFirstNameSuccess {
  static readonly type = '[User] User First Name Success';
  constructor(public firstName: any, public identifier: any) {}
}

export class UserFirstNameFailed {
  static readonly type = '[User] User First Name Failed';
  constructor(public identifier: any) {}
}

export class GetUserBalanceAndFirstName {
  static readonly type = '[User] Get Balance And First name';
  constructor(public type: string, public identifier: any) {}
}

export class GetUserBalance {
  static readonly type = '[User] Get User Balance';
  constructor(public type: string, public identifier: any) {}
}

export class UserBalanceSuccess {
  static readonly type = '[User] User Balance Success';
  constructor(public userBalance: any) {}
}

export class UserBalanceFailed {
  static readonly type = '[User] User Balance Failed';
}

export class ResetUserDetails {
  static readonly type = '[User] Reset User Details';
}

export class ResetUserErrors {
  static readonly type = '[User] Reset Errors';
  constructor() {}
}

export class GetNameFromCookie {
  static readonly type = '[User] Get name from cookie';
  constructor(public cookie: any) {}
}

export class UserLogin {
  static readonly type = '[User] Login';
  constructor(public user: any) {}
}

export class UserDPULoginSuccess {
  static readonly type = '[User] DPU Login success';
  constructor(public wxLoginToken: any) {}
}

export class UserLoginSuccess {
  static readonly type = '[User] Login success';
  constructor(public data: any) {}
}

export class UserLoginFailed {
  static readonly type = '[User] Login failed';
  constructor(public error: any) {}
}

export class UserIdentificationAttempts {
  static readonly type = '[User] Identification Attempts';
  constructor(public attemptify: any) {}
}

export class GetAllAttemptifyFromCookie {
  static readonly type = '[User] Get All Attemptify From Cookie';
}

export class CleanAllAttemptifyFromCookie {
  static readonly type = '[User] Clean All Attemptify From Cookie';
}

