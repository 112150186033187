import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

/**
 * LoaderService
 */
@Injectable({ providedIn: 'root' })
export class LoaderService {
  public loaderVisibilitySubject = new BehaviorSubject<boolean>(false);
  public loaderVisibility = this.loaderVisibilitySubject.asObservable();

  /**
   * Creates an instance of loader service.
   */
  constructor() {}

  /**
   * Shows loading indicator
   */
  showLoader() {
    this.loaderVisibilitySubject.next(true);
  }

  /**
   * Hides loading indicator
   */
  hideLoader() {
    this.loaderVisibilitySubject.next(false);
  }
}
