import { environment } from 'src/environments/environment';
import { HttpParams } from '@angular/common/http';

/**
 * Api service helper
 */
export class ApiServiceHelper {
  /**
   * Generates wwr apigee url
   * @param url relative url
   * @returns  full wwr apigee url
   */
  static generateApigeeUrl(url) {
    return environment.apigeeUrl + url;
  }

  /**
   * Generates wwr oam url
   * @param url relative url
   * @returns  full wwr oam url
   */
  static generateOamUrl(url) {
    return environment.apigeeUrl + '/v1/oam/' + url;
  }

  /**
   * Generates wwr oam url
   * @param url relative url
   * @returns  full wwr oam url
   */
  static generateCslUrl(url) {
    return environment.cslApiUrl + url;
  }

  /**
   * Generate the query parameters form the url
   * @param [urlParams] optional query parameters
   * @returns query params array
   */
  static generateUrlQueryParams(urlParams: Object = null) {
    let Params = new HttpParams();

    if (urlParams) {
      for (const property in urlParams) {
        if (urlParams.hasOwnProperty(property)) {
          Params = Params.append(property, urlParams[property]);
        }
      }
    }

    return Params;
  }
}
